import React, { Component } from 'react';
import { View, Text, Image, StyleSheet, TouchableOpacity, Modal } from 'react-native';
import { connect } from 'react-redux';
import Iframe from 'react-iframe';
const getThumbnailUrl = (_data) => {
    try {
        if (_data) {
            return _data.results.thumbnails[0];
        } else {
            return null;
        }
    } catch (error) {
        return null;
    }
}
const getVideoUrl = (_data) => {
    try {
        if (_data) {
            const videoUrl = `https://www.youtube.com/embed/${_data.results.id}`;
            return videoUrl;
        } else {
            return null;
        }
    } catch (error) {
        return null;
    }
}
class VideoThumbnail extends Component {
    state = {
        isModalVisible: false,
    };

    handleOpenModal = () => {
        this.setState({ isModalVisible: true });
    };

    handleCloseModal = () => {
        this.setState({ isModalVisible: false });
    };

    render() {
        const { video } = this.props; // Access video data from Redux
        const { isModalVisible } = this.state;

        if (!video || !video.show_video) {
            return (
                <View style={styles.container}>
                    {/* <Text style={styles.errorText}>No video data available</Text> */}
                </View>
            );
        }

        const thumbnailUrl = getThumbnailUrl(video);
        const videoUrl = getVideoUrl(video);

        if (!thumbnailUrl || !videoUrl) {
            return (
                <View style={styles.container}>
                </View>
            );
        }
        return (
            <View style={styles.container}>
                {/* Thumbnail */}
                <TouchableOpacity onPress={this.handleOpenModal}>
                    <Image source={{ uri: thumbnailUrl }} style={styles.thumbnail} />
                </TouchableOpacity>
                <View style={styles.details}>
                    <Text style={styles.title} numberOfLines={2}>
                        {video.title}
                    </Text>
                    {/* <Text style={styles.channel}>{video.channel}</Text>
                    <Text style={styles.meta}>
                        {video.duration} • {video.views} • {video.publish_time}
                    </Text> */}
                </View>

                {/* Full-Screen Iframe Modal */}
                <Modal visible={isModalVisible} animationType="slide" transparent={true}>
                    <View style={styles.modalContainer}>
                        <TouchableOpacity style={styles.closeButton} onPress={this.handleCloseModal}>
                            <Text style={styles.closeText}>✕</Text>
                        </TouchableOpacity>
                        <View style={styles.iframeContainer}>
                            <Iframe
                                rel="preload"
                                url={videoUrl}
                                width="100%"
                                height="100%"
                                style={styles.iframe}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="Video"
                                id="world-iframe"
                                className="iframe-3dmeet"
                                display="initial"
                                position="absolute"
                                scrolling="no"
                            ></Iframe>
                        </View>
                    </View>
                </Modal>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        position: 'fixed',
        top: 10,
        left: 10,
        width: 300,
        backgroundColor: '#fff',
        borderRadius: 8,
        overflow: 'hidden',
        shadowColor: '#000',
        shadowOpacity: 0.2,
        shadowRadius: 4,
        shadowOffset: { width: 0, height: 2 },
        elevation: 5,
    },
    thumbnail: {
        width: '100%',
        height: 170,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
    },
    details: {
        padding: 10,
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 5,
        color: '#333',
    },
    channel: {
        fontSize: 14,
        color: '#666',
        marginBottom: 5,
    },
    meta: {
        fontSize: 12,
        color: '#999',
    },
    modalContainer: {
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    closeButton: {
        position: 'absolute',
        top: 40,
        right: 20,
        zIndex: 10,
        backgroundColor: '#fff',
        borderRadius: 20,
        padding: 10,
    },
    closeText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#333',
    },
    iframeContainer: {
        width: '90%',
        height: '70%',
    },
    iframe: {
        width: '100%',
        height: '100%',
        borderRadius: 10,
    },
    errorText: {
        fontSize: 16,
        color: '#999',
        textAlign: 'center',
        marginTop: 20,
    },
});

// Map Redux state to props
const mapStateToProps = (state) => ({
    video: state.communication.speechToTextService.data,
});

export default connect(mapStateToProps)(VideoThumbnail);
