import {
    START_AI_ONETOONE_MEETING_LISTENING,
    START_AI_TEACHER_LISTENING,
    STOP_AI_ONETOONE_MEETING_LISTENING,
    STOP_AI_TEACHER_LISTENING,
    SET_SESSION_ID,
    FETCH_RESOURCES_REQUEST,
    FETCH_RESOURCES_SUCCESS,
    FETCH_RESOURCES_FAILURE,
} from "../actions/actionTypes"

export const initialState = {
    AITeacherListeningStatus: false,
    oneToOneMeetingStatus: false,
    data: null,
    error: null,
    loading: false,
    sessionId: null,
}

const speechToTextService = (state = initialState, action) => {

    switch (action.type) {
        case START_AI_TEACHER_LISTENING:
            return { ...state, AITeacherListeningStatus: true }
        case STOP_AI_TEACHER_LISTENING:
            return { ...state, AITeacherListeningStatus: false }
        case START_AI_ONETOONE_MEETING_LISTENING:
            return { ...state, oneToOneMeetingStatus: true }
        case STOP_AI_ONETOONE_MEETING_LISTENING:
            return { ...state, oneToOneMeetingStatus: false, data: null }
        case SET_SESSION_ID:
            return { ...state, sessionId: action.payload };
        case FETCH_RESOURCES_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_RESOURCES_SUCCESS:
            return { ...state, loading: false, data: action.payload };
        case FETCH_RESOURCES_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
}

export default speechToTextService;