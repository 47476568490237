import * as types from './actionTypes';

export const joinMeeting = (status) => {
  return {
    type: types.JOIN_MEETING,
    payload: status,
  };
};

export const setAvatarURL = (url) => {
  return {
    type: types.SET_AVATAR_URL,
    payload: url,
  };
};

export const setDirectLanding = (land) => {
  return {
    type: types.SET_DIRECT_LANDING,
    payload: land,
  };
};

export const setReadyToMove = (ready_to_move = false) => {
  return {
    type: types.SET_READY_TO_MOVE,
    payload: ready_to_move,
  };
};
