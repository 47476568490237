import axios from "axios";
import {
    START_AI_TEACHER_LISTENING,
    STOP_AI_TEACHER_LISTENING,
    START_AI_ONETOONE_MEETING_LISTENING,
    STOP_AI_ONETOONE_MEETING_LISTENING,

    SET_SESSION_ID,
    FETCH_RESOURCES_REQUEST,
    FETCH_RESOURCES_SUCCESS,
    FETCH_RESOURCES_FAILURE,
} from "./actionTypes";

export const startAITeacherListening = () => {
    return (dispatch) => {
        dispatch({
            type: START_AI_TEACHER_LISTENING,
        })
    };
}

export const stopAITeacherListening = () => {
    return (dispatch) => {
        dispatch({
            type: STOP_AI_TEACHER_LISTENING,
        })
    };
}

export const startOneOnOneMeeting = () => {
    return (dispatch) => {
        dispatch({
            type: START_AI_ONETOONE_MEETING_LISTENING,
        })
    };
}

export const stopOneOnOneMeeting = () => {
    return (dispatch) => {
        dispatch({
            type: STOP_AI_ONETOONE_MEETING_LISTENING,
        })
    };
}

// Set session ID
export const setSessionId = (sessionId) => ({
    type: SET_SESSION_ID,
    payload: sessionId,
});

// Fetch resources (async action using redux-thunk)
export const fetchResources = () => {
    return async (dispatch, getState) => {
        const { sessionId } = getState().communication.speechToTextService;
        console.log('fetchResources', sessionId);

        if (!sessionId) {
            dispatch({
                type: FETCH_RESOURCES_FAILURE,
                payload: "Session ID is required",
            });
            return;
        }

        dispatch({ type: FETCH_RESOURCES_REQUEST });

        try {
            const response = await axios.post(
                'https://livekit-token.quiklearn.ai/get_external_resources',
                { session_id: sessionId },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.data?.status === 200) {
                dispatch({
                    type: FETCH_RESOURCES_SUCCESS,
                    payload: response.data,
                });
            } else {
                dispatch({
                    type: FETCH_RESOURCES_FAILURE,
                    payload: "Unexpected response format or status code",
                });
            }
        } catch (error) {
            dispatch({
                type: FETCH_RESOURCES_FAILURE,
                payload: error.message || "An error occurred",
            });
        }
    };
};