// src/hooks/useFetchToken.js
import { useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const useFetchTokenLiveKit = () => {
    const [token, setToken] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const getToken = ({
        teacherName,
        courseName,
        voice_id,
        courseId,
        userId,
        session_id
    }) => {
        return new Promise(async (resolve, reject) => {
            setLoading(true);

            try {
                const response = await axios.post(
                    'https://livekit-token.quiklearn.ai/get_token',
                    {
                        instructor: teacherName,
                        course: courseName,
                        metadata: JSON.stringify({
                            voice_id: voice_id,
                            institution: 'quiklearn',
                            courseId,
                            userId,
                            session_id: session_id
                        }),
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                );
                const token = response.data.token; // Adjust according to the response structure
                setToken(token);
                setError(null);
                resolve(token);
            } catch (err) {
                console.error('getToken', err);
                setError(err.message || 'An error occurred');
                setToken(null);
                reject(err);
            } finally {
                setLoading(false);
            }
        });
    };


    return { token, error, loading, getToken };
};

export default useFetchTokenLiveKit;
