import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Platform, PixelRatio, StyleSheet } from 'react-native';

import { COMMAND_ROUTE, USER_ROUTE, VIEWBOARD_SYNC } from 'utils/World/config';
import PlatformWorld from 'components/World/PlatformWorld';
import Loading from 'components/World/Loading';
import TextOverlayManager from 'components/World/TextOverlayManager';
import LabelDisplay from 'components/World/LabelDisplay';
import { startAITeacherListening, stopAITeacherListening } from 'store/communication/actions/speechToTextServiceActions';
import { disableWebboard, enableWebboard } from 'store/media';
import { joinMeeting, setAvatarURL, setReadyToMove } from 'store/meeting/actions/meeting';
import { setCourseProgress } from 'store/app/actions';
import { connect } from 'react-redux';
import { QUIKLEARN_BACKEND_URL } from 'config/app.config';
import { showMessage } from 'react-native-flash-message';
import { showCourseCompleteMessage, courseComplete } from 'store/communication/actions/courseActions';
import { sendWebhookData } from 'utils/communication/sendWebhookData';

async function updateCourseProgress(courseId, userId, courseProgressState) {
  try {
    const progressValue = parseFloat(courseProgressState);

    if (isNaN(progressValue)) {
      throw new Error('Invalid progress data: not a number');
    }

    const requestBody = {
      progress: progressValue
    };

    const response = await fetch(`${QUIKLEARN_BACKEND_URL}/staging/course/${courseId}/progress/${userId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const result = await response.json();
    console.log('Course progress updated successfully:', result);
  } catch (error) {
    console.error('Error updating course progress:', error);
  }
}

class World extends Component {
  static instance = undefined;
  constructor(props) {
    super(props);
    this.state = {
      loading: true, lowPower: false, newAvatarUrl: "", userId: "",
      courseId: "", courseProgress: "", isStarted: false, entryTime: null,
      exitTime: null
    };
  }

  componentDidMount() {
    if (World.instance) {
      throw new Error('Cannot have multiple 3d world components mounted');
    }
    World.instance = this;
    window.onbeforeunload = (event) => {
      this.handleExit();
      event.returnValue = "Are you sure you want to leave?";
    };
  }
  handleExit = () => {
    let _exitTime = this.state.exitTime;
    if (this.state.isStarted) {
      const entry = Date.now();  // Using high-resolution time measurement
      this.setState({
        exitTime: entry,
      })
      _exitTime = entry
    }
    const timeSpent = _exitTime !== null && this.state.entryTime !== null ? (_exitTime - this.state.entryTime) / 1000 : null;
    if (timeSpent) {
      console.log('handleExit', timeSpent)
      const payload = {
        message: 'Course Exit!',
        timestamp: Date.now() + "",
        extra: JSON.stringify({
          "timeSpent": timeSpent,
          "userAgent": navigator.userAgent,
          "isPromotional": this.props.isPromotional,
          "userId": this.props.userId,
          "courseId": this.props.courseId,
          "courseProgress": this.props.courseProgress
        }),
      };
      try {
        sendWebhookData(payload); //Parth added hooks for exit course/complete
      } catch (error) {
        console.error(error);
      }
    }
  };
  componentWillUnmount() {
    World.instance = undefined;
    this.handleExit();

    // Clean up unload listener
    window.onbeforeunload = null;

  }

  shouldComponentUpdate(nextProps) {
    const { location } = this.props;
    if (nextProps.location !== location) {
      this.setState({ loading: true });
    }

    if (nextProps.lowPower !== this.state.lowPower) {
      this.setPowerMode(nextProps.lowPower);
    }

    return true;
  }

  static runCommand = (command) => {
    console.log('COMMAND ROUTE: ', COMMAND_ROUTE);
    console.log('COMMAND: ', command);

    PlatformWorld.sendMessage({
      route: COMMAND_ROUTE,
      payload: command,
    });
  };

  static onReceivePeerMessage = (message) => {
    if (message.route === 'j' || message.route === USER_ROUTE) {
      message.payload = `${message.peer}|${message.payload}`;
      PlatformWorld.sendMessage(message);
    } else if (message.action === 'teleport') {
      World.runCommand(`player_leave ${message.peer}`);
    } else if (message.route === VIEWBOARD_SYNC) {
      PlatformWorld.sendMessage(message);
    }
  };

  static onReceivePeerState = (peerName, state) => {
    if (
      state &&
      state.appearance &&
      typeof state.appearance !== 'undefined' &&
      Object.keys(state.appearance).length !== 0
    ) {
      PlatformWorld.sendMessage({
        route: 'd',
        payload: `${peerName}|${state.appearance}`,
      });
    }
  };

  static onUpdatePeerFloatingName = (peerId, peerDisplayName) => {
    if (peerId && peerDisplayName) {
      PlatformWorld.sendMessage({
        route: USER_ROUTE,
        payload: `${peerId}|nickname|${peerDisplayName}`,
      });
    }
  };

  filterTransformMessages = (message) => {
    const { onTransformFromWorld } = this.props;
    const t = message.value;
    const values = `${t.x} ${t.y} ${t.z} ${t.rx} ${t.ry} ${t.rz} ${t.time}`;
    const payload = `transform|${values}|${t.filter}`;

    onTransformFromWorld({ route: USER_ROUTE, payload });
  };

  componentDidUpdate(prevProps, prevState) {
    // Check if newAvatarUrl has changed
    if (prevState.newAvatarUrl !== this.state.newAvatarUrl) {
      const { newAvatarUrl } = this.state;
      console.log("Usman new avatar url after state change ::: " + newAvatarUrl);

    }
  }

  filterLegacyMessages = (message) => {
    const { onLegacyFromWorld } = this.props;
    const { route, payload } = message;
    // console.log('filterLegacyMessages', route, payload);

    if (route === 'x') {
      this.onLevelLoadComplete();
    } else if (route === 'p') {
      // ignore the old/erroneous command that sends a number with the p. In that case the payload will be undefined.
      if (!payload) {
        return;
      }
      const player_variables = payload.split('|');
      if (player_variables[0] === 'rpmReady') {
        console.log("Usman RPM ready recieved");
        this.setState({ loading: false });
      }
      if (player_variables[0] === 'updatedAppearance') {
        // player var for knowing when unity has finished updating an avatar.
        if (player_variables[1] === 'System.Object') {
          // This particular variable is a false variable that Unity sends on initialization. We need to ignore it here.
          return;
        }
        // this is a completed avatar appearance. Update local state and send the message through to be processed.
        this.setState({ loading: false });
        onLegacyFromWorld(message);
      }
    } else if (route === 'c') {
      const payload_variables = payload.split('|');
      if (payload === 'startAITeacherListening') {
        const { dispatch } = this.props;
        dispatch(startAITeacherListening());
      }
      else if (payload === 'stopAITeacherListening') {
        const { dispatch } = this.props;
        dispatch(stopAITeacherListening());
      }
      else if (payload_variables[0] === 'setWebboardUrl') {
        var showStatus = payload_variables[2];
        if (showStatus === 'show') {
          var url = payload_variables[1];
          const { dispatch } = this.props;
          dispatch(enableWebboard(payload_variables[1]));
        }
        else if (showStatus === 'hide') {
          const { dispatch } = this.props;
          dispatch(disableWebboard());
        }
      }
      else if (payload === 'joinMeeting') {
        const { dispatch } = this.props;
        dispatch(joinMeeting(true));
      }
      else if (payload === 'ready_to_move') {
        const { dispatch } = this.props;
        dispatch(setReadyToMove(true));
      }
      else if (payload_variables[0] === 'avatarurl') {
        var avatarurl = payload_variables[1];
        const { dispatch } = this.props;
        dispatch(setAvatarURL(avatarurl));
        this.setState({ newAvatarUrl: avatarurl });
      }
      // this is the course state shared from unity side along with video index so use it according to your need
      else if (payload_variables[0] === 'courseState') {
        var videoIndex = payload_variables[1];
        const { dispatch } = this.props;
        dispatch(setCourseProgress(videoIndex))
        console.log("courseState recieved from Unity: " + videoIndex);
        updateCourseProgress(this.props.courseId, this.props.userId, videoIndex);
        this.setState({ courseProgress: videoIndex });
        console.log("this.state.courseProgress: " + this.state.courseProgress);
      }
      else if (payload_variables[0] === 'lessonEnded') {
        const { dispatch } = this.props;
        dispatch(showCourseCompleteMessage());
        dispatch(courseComplete());
        const entry = Date.now();  // Using high-resolution time measurement
        this.setState({
          exitTime: entry,
        })
      }
      else if (payload === 'courseStarted') {
        const entry = Date.now();  // Using high-resolution time measurement
        this.setState({
          entryTime: entry,
          isStarted: true
        })
      }
    }
    else {
      onLegacyFromWorld(message);
    }
  };

  appearanceComplete = (message) => {
    const { onAppearanceCompleteFromWorld } = this.props;
    onAppearanceCompleteFromWorld(message);
  };

  setPowerMode(lowPower) {
    if (lowPower) {
      PlatformWorld.sendMessage({
        route: COMMAND_ROUTE,
        payload: 'set_quality LOW',
      });
      PlatformWorld.sendMessage({
        route: COMMAND_ROUTE,
        payload: 'set_fps_limit 24',
      });
    } else {
      PlatformWorld.sendMessage({
        route: COMMAND_ROUTE,
        payload: 'set_quality HIGH',
      });
      PlatformWorld.sendMessage({
        route: COMMAND_ROUTE,
        payload: 'set_fps_limit 30',
      });
    }

    this.setState({ lowPower: lowPower });
  }

  onLevelLoadComplete = () => {
    const {
      locationFilter,
      onLoadComplete,
      lowPower,
      showFloatingNames,
    } = this.props;

    PlatformWorld.sendMessage({
      route: COMMAND_ROUTE,
      payload: `set_location_filter ${locationFilter}`,
    });
    PlatformWorld.sendMessage({
      route: COMMAND_ROUTE,
      payload: 'finish_loading',
    });
    PlatformWorld.sendMessage({ route: COMMAND_ROUTE, payload: 'after_join' });

    PlatformWorld.sendMessage({
      route: COMMAND_ROUTE,
      payload: `show_names ${showFloatingNames}`,
    });
    PlatformWorld.sendMessage({
      route: COMMAND_ROUTE,
      payload: 'show_chat false',
    });

    this.setPowerMode(lowPower);

    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      PlatformWorld.sendMessage({
        route: COMMAND_ROUTE,
        payload: 'show_thumbstick true',
      });

      const thumbSize = PixelRatio.getPixelSizeForLayoutSize(56);
      const thumbTravel = PixelRatio.getPixelSizeForLayoutSize(112);
      const thumbX = PixelRatio.getPixelSizeForLayoutSize(84);
      const thumbY = PixelRatio.getPixelSizeForLayoutSize(315);
      PlatformWorld.sendMessage({
        route: COMMAND_ROUTE,
        payload: `set_thumbstick ${thumbX} ${thumbY} ${thumbSize} ${thumbTravel}`,
      });
    } else {
      PlatformWorld.sendMessage({ route: COMMAND_ROUTE, payload: `direct_landing ${this.props.direct_landing}` });
      PlatformWorld.sendMessage({ route: COMMAND_ROUTE, payload: `set_avatar_url ${this.props.newAvatarUrl} ${this.props.direct_landing}` });
    }
    onLoadComplete();
  };

  render() {
    const { location, style } = this.props;
    const { loading } = this.state;

    return (
      <View pointerEvents="box-none" style={[styles.background, style]}>
        {location && (
          <PlatformWorld
            location={location}
            onTransform={(message) => this.filterTransformMessages(message)}
            onLegacyMessage={(message) => this.filterLegacyMessages(message)}
          />
        )}
        <Loading loading={loading} />
        <TextOverlayManager loading={loading} />
        <LabelDisplay loading={loading} />
      </View>
    );
  }
}

World.defaultProps = {
  style: {},
  onTransformFromWorld: () => { },
  onLegacyFromWorld: () => { },
  onLoadComplete: () => { },
  lowPower: false,
  showFloatingNames: false,
};

World.propTypes = {
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  location: PropTypes.string.isRequired,
  locationFilter: PropTypes.string.isRequired,
  onTransformFromWorld: PropTypes.func,
  onLegacyFromWorld: PropTypes.func,
  onLoadComplete: PropTypes.func,
  lowPower: PropTypes.bool,
  showFloatingNames: PropTypes.bool,
  newAvatarUrl: PropTypes.string,
  userId: PropTypes.string,
  courseId: PropTypes.string,
  courseProgress: PropTypes.string,
  isPromotional: PropTypes.bool
};

const mapStateToProps = (state) => ({
  newAvatarUrl: state.meeting.meeting.avatarUrlValue,
  userId: state.app.courseTokenValues.userId,
  courseId: state.app.courseTokenValues.courseId,
  courseProgress: state.app.courseTokenValues.courseProgress,
  isPromotional: state.app.courseTokenValues.isPromotional,
  direct_landing: state.meeting.meeting.direct_landing
});

const styles = StyleSheet.create({
  background: {
    backgroundColor: '#000',
  },
});

export default connect(mapStateToProps)(World);
