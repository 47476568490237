import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryParam, StringParam, BooleanParam, } from 'use-query-params';

import { initRemoteConfig } from 'store/app/remoteConfigActions';
import { initDynamicLinkHandler } from 'store/app/linkActions';
import { initCache } from 'store/app/cacheActions';
import { setDirectLanding, setReadyToMove } from 'store/meeting/actions/meeting';

const RemoteConfigHandler = () => {
  const dispatch = useDispatch();
  const isRemoteConfigLoaded = useSelector(
    (state) => state.app.isRemoteConfigLoaded,
  );

  useEffect(() => {
    if (Platform.OS === 'web') {
      if (!isRemoteConfigLoaded) {
        dispatch(initRemoteConfig());
      }
    }
  }, [isRemoteConfigLoaded]); // Add token to dependencies to ensure correctness


  // Web breaks for some reason using useEffect to call this
  console.log('RemoteConfigHandler', Date.now());

  return <></>;
};
const IntialLink = () => {
  const dispatch = useDispatch();
  const [token] = useQueryParam('token', StringParam);
  const [avatarSelectionString] = useQueryParam('avatarSelection', StringParam);

  // avatarSelection
  if (Platform.OS === 'web' && token) {
    const avatarSelection = avatarSelectionString === 'true'; // 'true' becomes true, everything else becomes false

    dispatch(setDirectLanding(!avatarSelection));
    dispatch(setReadyToMove(false));

    dispatch(initDynamicLinkHandler(token, avatarSelection)); // Always executes once for web
  }
  return (<>
  </>)
}
export default () => {
  return (<>
    <RemoteConfigHandler />
    <IntialLink />
  </>)
};
