import React from 'react';
import {
    useLocalParticipant,
    useTrackTranscription,
} from "@livekit/components-react";
import World from "components/World";
import { Track } from "livekit-client";
import { useEffect, useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { fetchResources } from "store/communication/actions/speechToTextServiceActions";

const TranscriptionTile = React.memo(({ agentAudioTrack }) => {
    const dispatch = useDispatch();

    const agentMessages = useTrackTranscription(agentAudioTrack);
    const localParticipant = useLocalParticipant();
    const localMessages = useTrackTranscription({
        publication: localParticipant.microphoneTrack,
        source: Track.Source.Microphone,
        participant: localParticipant.localParticipant,
    });
    const timeoutRef = React.useRef(null); // Ref to store the timeout id

    // Callback to dispatch fetchResources only when necessary
    const handleAgentFinalMessage = useCallback(
        (id, text) => {
            console.log("Sending Agent Transcript:", {
                id,
                text,
                final: true,
                participant: "tutor",
            });
            World.runCommand(
                `livekit_final_response ${id} ${text.replace(/ /g, "_")} true tutor`
            );

        },
        [dispatch]
    );

    const handleLocalMessage = useCallback((id, text, isFinal) => {
        console.log("Sending Local Transcript:", {
            id,
            text,
            final: isFinal,
            participant: "player",
        });
        World.runCommand(
            `livekit_final_response ${id} ${text.replace(/ /g, "_")} ${isFinal} player`
        );
        if (isFinal) {
            // If there's a previous timeout, clear it
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            // Set a new timeout for 1 second
            timeoutRef.current = setTimeout(() => {
                dispatch(fetchResources());
            }, 2 * 1000); // 2-second delay before dispatching the API call
        }

    }, []);

    // Process agent messages
    useEffect(() => {
        agentMessages.segments.forEach((s) => {
            if (s.final) {
                handleAgentFinalMessage(s.id, s.text);
            }
        });
    }, [agentMessages.segments, handleAgentFinalMessage]);

    // Process local participant (customer) messages
    useEffect(() => {
        localMessages.segments.forEach((s) => {
            handleLocalMessage(s.id, s.text, s.final);
        });
    }, [localMessages.segments, handleLocalMessage]);

    return null;
});

export { TranscriptionTile };
